import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { GetPaymentSummaryCounters } from '../actions/payment-summary-counters.action';
import { AccountService } from '../services/account-service/account-service.service';
import { ConfigService } from '../services/config-service/config-service.service';

export interface PaymentSummaryCounterStateModel {
  [key: string]: number;
}

@State<PaymentSummaryCounterStateModel>({
  name: 'paymentSummaryCounter',
  defaults: {
    accountsCounter: 0,
    lastDayTotalCounter: 0,
    pendingCounter: 0,
    lastDayApprovalCounter: 0,
  },
})
@Injectable()
export class PaymentSummaryCounterState implements NgxsOnInit {
  constructor(private accountService: AccountService, private configService: ConfigService) {}
  ngxsOnInit(ctx: StateContext<PaymentSummaryCounterStateModel>): void {
    if (this.configService.getActiveConfigType().isWorkflow) {
      ctx.setState({
        accountsCounter: 12,
        lastDayTotalCounter: 230,
        pendingCounter: 28,
        lastDayApprovalCounter: 200,
      });
    }
  }

  @Selector()
  static getPaymentSummaryCounters(state: PaymentSummaryCounterStateModel) {
    return state;
  }

  //update this and the action to so that the action data passed is a string and then pass the string to the account service and set each string value to the state
  @Action(GetPaymentSummaryCounters)
  fetchPaymentSummaryCounters(
    ctx: StateContext<PaymentSummaryCounterStateModel>,
    action: GetPaymentSummaryCounters,
  ) {
    return this.accountService.getPaymentSummaryCounters(action.queryParams).pipe(
      tap((response) => {
        for (const [key, value] of Object.entries(response)) {
          ctx.setState({
            ...ctx.getState(),
            [key]: value,
          });
        }
      }),
    );
  }
}

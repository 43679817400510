import { CurrencyExchangeTransactionType, TransactionType } from '@finxone-platform/shared/sys-config-types';

export class GetTransactions {
  static readonly type = '[Account] GetTransactions';
  constructor(
    public accountId: string,
    public dateFrom?: string | null,
    public dateTo?: string | null,
    public page = 1,
    public limit = 5,
    public transactionType: TransactionType | CurrencyExchangeTransactionType = TransactionType.INCOMING,
    public currency = '',
    public isLoaderShown = true,
  ) {}
}

export class SetLoadingStatus {
  static readonly type = '[Account] SetLoadingStatus';
  constructor(public isLoading: boolean) {}
}
export class ClearTransactionsCache {
  static readonly type = '[Account] ClearTransactionsCache';
}

export class GetExchangeTransactionById {
  static readonly type = '[Account] getExchangeTransactionById';
  constructor(public exchangeTransactionId: string, public transactionType: string) {}
}

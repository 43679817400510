import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { unauthenticatedRoutes } from '@app/finxone-web-frontend/app/lib/utils/auth-checks.utils';
import { formatNameAsUrl } from '@app/finxone-web-frontend/app/lib/utils/zone-url.utils';
import {
  APP_ZONES,
  BaseCssProperties,
  CurrencyExchangeTransactionType,
  ProviderClass,
  TransactionType,
  UiPageConfig,
  WidgetNames,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import jwt_decode from 'jwt-decode';
import { Currency } from '../dtos/currencies.dto';
import { ConfigService } from '../services/config-service/config-service.service';
import { formWidgets } from '../services/form-submission-service/form-submission-service.service';
import { StyleServiceService } from '../services/style-service/style-service.service';
import { RoleStateModel } from '../state/role.state';
import { checkMinAgeValidation, validDayOfMonthYearValidator } from './validators.utils';

export function AppendDataBase64(base64: string) {
  return `data:image/png;base64,${base64}`;
}

export function TextToUnderscore(sourceString: string): string {
  sourceString = sourceString.trim().toLowerCase();
  sourceString = sourceString.replace(/[^A-Z0-9]+/gi, '_');
  sourceString = sourceString.replace(/_$/, '');

  return sourceString;
}

export function GeneratePassword() {
  const checkboxes = [
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPWRSTUVWXYZ',
    '0123456789',
    "!@#$%^&*-_=+\\|:;',.<>/?~",
  ];

  const passwordLenght = 10;
  let dictionary: Array<string> = [];
  // eslint-disable-next-line prefer-const
  dictionary = checkboxes[0]
    .split('')
    .concat(checkboxes[1].split(''), checkboxes[2].split(''), checkboxes[3].split(''));

  // Generate random password from array
  let newPassword = '';
  for (let i = 0; i < passwordLenght; i++) {
    newPassword += dictionary[Math.floor(Math.random() * dictionary.length)];
  }
  return newPassword;
}

export function createCompareValidator(
  controlOne: AbstractControl | null,
  controlTwo: AbstractControl | null,
) {
  return () => {
    if (controlOne && controlTwo && controlOne.value !== controlTwo.value)
      return { match_error: 'Value does not match' };
    return null;
  };
}

export function getSplittedAccountNumber(accountNumber: string) {
  return '************' + accountNumber.slice(accountNumber.length - 4, accountNumber.length);
}

export function isFormWidget(pageIndex: number, zonePagesList: UiPageConfig[]): boolean {
  if (pageIndex >= 0 && pageIndex < zonePagesList.length) {
    return zonePagesList[pageIndex].widgetLayouts.some((widgetLayout) =>
      formWidgets.includes(widgetLayout.name as WidgetNames),
    );
  }
  return false;
}

export function createDateString(dateString: Date): string {
  if (dateString instanceof Date && !isNaN(dateString?.getTime())) {
    const dob = new Date(dateString).toLocaleDateString('en-GB').split('/').reverse().join('-');
    return new Date(dob).toISOString().split('T')[0];
  } else {
    return '1995-06-18';
  }
}
export function isTokenExpired(token: string): boolean {
  const jwtToken: Record<string, string | number> = jwt_decode(token);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const isExpired = Number(jwtToken?.['exp']) < currentTimestamp;
  return isExpired;
}

export function isShowAndErorIcon(elementsConfig: any[]): {
  isShowErrorIcon: boolean;
  isShowSuccessIcon: boolean;
} {
  const showErorIcon = elementsConfig?.find((item) => item.field_name === 'isShowErrorIcon');
  const showSuccessIcon = elementsConfig?.find((item) => item.field_name === 'isShowSuccessIcon');
  return {
    isShowErrorIcon: showErorIcon?.show ?? false,
    isShowSuccessIcon: showSuccessIcon?.show ?? false,
  };
}
export const setCssAttributes = (
  styleService: StyleServiceService,
  configService: ConfigService,
  attri: any, // Replace 'any' with the appropriate type for attri
) => {
  const styles = styleService.generateStyles(attri);
  const contentStyle = styles.contentStyle;
  const baseWidgetStyle = styles.baseWidgetStyle;
  const colorTextAlignFontStyle = styles.colorTextAlignFontStyle;
  const colorStyle = styles.colorStyle;
  const colorTextAlignStyle = styles.colorTextAlignStyle;

  const borderStyle = configService.getSpecificBaseCssStyle(attri, [
    BaseCssProperties.BORDER,
    BaseCssProperties.BACKGROUND_COLOR,
  ]);
  const textInputFontStyle = configService.getSpecificBaseCssStyle(attri, [
    BaseCssProperties.TEXT_INPUT_FONT_SIZE,
    BaseCssProperties.TEXT_FONT_STYLE,
  ]);
  const textInputBackgroundColor = configService.getSpecificBaseCssStyle(attri, [
    BaseCssProperties.TEXT_INPUT_BG_COLOR,
  ]);

  const textContentStyle = configService.getSpecificBaseCssStyle(attri, [
    BaseCssProperties.TEXT_BOLD,
    BaseCssProperties.TEXT_ITALIC,
    BaseCssProperties.TEXT_UNDERLINE,
  ]);

  return {
    contentStyle,
    colorTextAlignFontStyle,
    colorStyle,
    borderStyle,
    textInputFontStyle,
    textInputBackgroundColor,
    colorTextAlignStyle,
    textContentStyle,
    baseWidgetStyle,
  };
};

export function checkRoleEquality(
  roleInfo: RoleStateModel,
  navigationData: { url: string; role: string; zone: string },
) {
  const isSameRole = roleInfo.role === navigationData.role;

  const isOnboardingOrVerification =
    navigationData.zone === APP_ZONES.ONBOARDING ||
    navigationData.zone === APP_ZONES.VERIFICATION ||
    navigationData.zone === APP_ZONES.ORGANISATION_ONBOARDING ||
    navigationData.zone === APP_ZONES.ORGANISATION_VERIFICATION;

  return { isSameRole, isOnboardingOrVerification };
}
export function createInputAgeDatePickerForm(
  selectedDay: number,
  selectedMonth: number,
  selectedYear: number,
  minYear: number,
  maxYear: number,
  minAge: number,
): FormGroup {
  return new FormGroup(
    {
      day: new FormControl(selectedDay, [Validators.required, Validators.min(1)]),
      month: new FormControl(selectedMonth, [Validators.required]),
      year: new FormControl(selectedYear, [
        Validators.required,
        Validators.min(minYear),
        Validators.max(maxYear),
      ]),
    },
    [validDayOfMonthYearValidator('year', 'month', 'day'), checkMinAgeValidation(minAge)],
  );
}

export function createInputDatePickerForm(
  selectedDay: number,
  selectedMonth: number,
  selectedYear: number,
  minYear: number,
  maxYear: number,
): FormGroup {
  return new FormGroup(
    {
      day: new FormControl(selectedDay, [Validators.required, Validators.min(1)]),
      month: new FormControl(selectedMonth, [Validators.required]),
      year: new FormControl(selectedYear, [
        Validators.required,
        Validators.min(minYear),
        Validators.max(maxYear),
      ]),
    },
    [validDayOfMonthYearValidator('year', 'month', 'day')],
  );
}

export const getFileType = (fileName: string) => {
  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(fileName)?.[1];
};

export function kebabToCamelCase(input: string) {
  return input.replace(/-([a-z])/g, (_, match) => match.toUpperCase());
}

export function removeDuplicateCurrencies(currencies: Currency[], key: keyof Currency): Currency[] {
  currencies.sort((a, b) => (a[key] > b[key] ? 1 : -1));
  return currencies.filter((obj, index, self) => index === self.findIndex((o) => o[key] === obj[key]));
}

export function removeInvalidCurrencies(currencies: Currency[]): Currency[] {
  return currencies.filter(
    (currency) => !currency.currency_code.includes('/') && !currency.currency_code.includes('-'),
  );
}

export function removeDuplicateEntriesAndCreateCurrenciesListData(
  currencies: Currency[],
  supportedCurrencies: string[],
) {
  const currenciesList: { code: string; isDisabled: boolean; name: string }[] = [];
  const validCurrencies = removeInvalidCurrencies(currencies);
  const currenciesArrayWithoutDuplicates: Currency[] = removeDuplicateCurrencies(
    validCurrencies,
    'currency_code',
  );
  currenciesArrayWithoutDuplicates.forEach((currency) => {
    currenciesList.push({
      code: currency.currency_code,
      name: currency.currency_name,
      isDisabled: isDisabledCurrency(supportedCurrencies, currency.currency_code),
    });
  });
  return currenciesList;
}

export function isDisabledCurrency(supportedCurrencies: string[], currency_code: string): boolean {
  if (supportedCurrencies.includes(currency_code)) {
    return false;
  } else {
    return true;
  }
}

export function removeDisabledCurrencies(
  currenciesList: { code: string; isDisabled: boolean; name: string }[],
) {
  return currenciesList.filter((currency) => currency.isDisabled === false);
}
export function setNavigationUrl(dataIn: ProviderClass<any>): string {
  let pageUrl: string | undefined;

  if (dataIn.attri['pageUrl']) {
    pageUrl = dataIn.attri['pageUrl'];
  }
  if (unauthenticatedRoutes.includes(dataIn.attri?.zone)) {
    pageUrl = `/zones/${formatNameAsUrl(dataIn.attri?.zone)}/${pageUrl}/${dataIn.attri?.role}/default`;
  } else if (pageUrl) {
    pageUrl = `/zones/${formatNameAsUrl(dataIn.attri?.zone)}/${pageUrl}`;
  } else {
    pageUrl = `/zones/${formatNameAsUrl(dataIn.attri?.zone)}`;
  }
  return pageUrl;
}

export function downloadResponse(response: unknown, documentType: string) {
  const blob = new Blob([response as BlobPart], {
    type: `application/${documentType}`,
  });
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = new Date().getTime() + `.${documentType}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(blobUrl);
}

export const removeSpaceFromString = (role: string) => {
  try {
    return role.toLowerCase().replace(/[^A-Z0-9]+/gi, '-');
  } catch (error) {
    return '';
  }
};

export function formatTime12HourFormat(date: Date): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strHours = hours < 10 ? `0${hours}` : hours.toString();
  const strMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  return `${strHours}:${strMinutes}`;
}

export function stripCommas(numberString: string) {
  return numberString.replace(/,/g, '');
}

export function flattenObject(obj: { [key: string]: any }): {
  [key: string]: any;
} {
  if (obj === null || obj === undefined) {
    return {};
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && typeof value === 'object') {
      return { ...acc, ...flattenObject(value) };
    } else {
      return { ...acc, [key]: value };
    }
  }, {});
}

export function updateRedirectPath(newPath: string): string {
  const path = location.pathname.split('/');
  path[3] = newPath;
  return path.join('/');
}

export function isLastPage(activePageIndex: number, zonePagesList: UiPageConfig[]): boolean {
  if (zonePagesList[activePageIndex + 2]) {
    return false;
  } else {
    return true;
  }
}

export function checkZonePagesListForNexPageUrls(
  startIndex: number,
  zonePagesList: UiPageConfig[],
  pagesMapping: { [key: string]: Record<string, string | boolean> } = {},
) {
  for (let index = startIndex; index < zonePagesList.length; index++) {
    if (!pagesMapping[index]?.['isVerificationPage']) {
      const url = zonePagesList[index]?.url;
      const pageIndex = index;

      return {
        url,
        pageIndex,
      };
    }
  }

  return undefined;
}

export function isDesktopNavbarVisible(zoneUrlInput: string): boolean {
  return [
    formatNameAsUrl(APP_ZONES.ONBOARDING),
    formatNameAsUrl(APP_ZONES.ORGANISATION_ONBOARDING),
    formatNameAsUrl(APP_ZONES.SIGNED_OUT_INTRODUCTION),
    formatNameAsUrl(APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION),
    formatNameAsUrl(APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD),
    formatNameAsUrl(APP_ZONES.VERIFICATION),
    formatNameAsUrl(APP_ZONES.ORGANISATION_VERIFICATION),
  ].includes(zoneUrlInput as APP_ZONES);
}

export function handleBackgroundShape(
  shape: 'circle' | 'square' | 'rectangle' | 'none',
  contentStyle: baseCssConfigStyle,
) {
  switch (shape) {
    case 'circle':
      contentStyle['border-bottom-left-radius'] = '50%';
      contentStyle['border-top-left-radius'] = '50%';
      contentStyle['border-bottom-right-radius'] = '50%';
      contentStyle['border-top-right-radius'] = '50%';
      return contentStyle;
    case 'square':
    case 'rectangle':
      contentStyle['border-bottom-left-radius'] = '0%';
      contentStyle['border-top-left-radius'] = '0%';
      contentStyle['border-bottom-right-radius'] = '0%';
      contentStyle['border-top-right-radius'] = '0%';
      return contentStyle;
    default:
      return contentStyle;
  }
}

export function clearLocalStorageExcept(items: string[]) {
  const itemsToKeep: { key: string; value: string }[] = [];
  items.forEach((item) => {
    const propertyValue = localStorage.getItem(item);
    if (propertyValue) {
      itemsToKeep.push({ key: item, value: propertyValue });
    }
  });
  localStorage.clear();
  itemsToKeep.forEach((item) => {
    localStorage.setItem(item.key, item.value);
  });
}

export function setAlternativeThemeFromQueryParam() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlAlternativeTheme = urlParams.get('alternativeTheme');

  if (urlAlternativeTheme) {
    localStorage.setItem('alternativeTheme', urlAlternativeTheme);
  }
}

export function getLoginRedirectUri(): string {
  setAlternativeThemeFromQueryParam();
  let redirectUri = window.location.origin + '/post-auth';
  const alternativeTheme = localStorage.getItem('alternativeTheme');
  if (alternativeTheme) {
    redirectUri = redirectUri + `?alternativeTheme=${alternativeTheme}`;
  }
  return redirectUri;
}

export function removeNullUndefinedValuesFromObject<TypeOfObject>(obj: TypeOfObject): TypeOfObject {
  for (const prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined) {
      delete obj[prop];
    } else if (typeof obj[prop] === 'object') {
      removeNullUndefinedValuesFromObject(obj[prop]);
    }
  }
  return obj;
}

export function mapFormData(formData: any, keyMapping?: any): { [key: string]: any } {
  const mappedFormData: { [key: string]: any } = {};
  for (const key in formData) {
    // eslint-disable-next-line no-prototype-builtins
    if (formData.hasOwnProperty(key)) {
      // Map the key if it exists in the keyMapping, otherwise keep the original key
      let mappedKey;
      if (!keyMapping) mappedKey = key;
      else {
        mappedKey = keyMapping[key] || key;
      }
      mappedFormData[mappedKey] = formData[key];
    }
  }
  return mappedFormData;
}

export function removeAsterisk(input: string) {
  return input.replace(/\*/g, '');
}

export function formatPAN(input: string): string {
  return input.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
}

export function formatDateMMYY(input: string): string {
  return new Date(input).toLocaleDateString('en-gb', { month: '2-digit', year: '2-digit' });
}

export function setTokenListToLocalStorage() {
  if (!localStorage.getItem('tokenList')) {
    const tokenList = [
      {
        dpanLastFour: '7689',
        fpanLastFour: '1234',
        issuerName: 'someName',
        tokenState: 'abc',
      },
    ];
    localStorage.setItem('tokenList', JSON.stringify(tokenList));
  }
}

export function mapCurrencyExchangeToTransactionType(
  type: CurrencyExchangeTransactionType | TransactionType,
): TransactionType {
  switch (type) {
    case CurrencyExchangeTransactionType.CURRENCYINCOMING:
      return TransactionType.INCOMING;
    case CurrencyExchangeTransactionType.CURRENCYOUTGOING:
      return TransactionType.OUTGOING;
    case CurrencyExchangeTransactionType.CURRENCYALL:
      return TransactionType.ALL;
    default:
      throw new Error('Unknown CurrencyExchangeTransactionType');
  }
}

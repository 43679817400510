/* eslint-disable @nx/enforce-module-boundaries */
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer, ɵDomSanitizerImpl } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { from, switchMap, take } from 'rxjs';
import { AppAuthGuard } from './auth.guard';
import { LoginComponent } from './lib/components/login/login.component';
import { httpInterceptor } from './lib/http-interceptor';
import { ConfigService } from './lib/services/config-service/config-service.service';
import { MultipartFormHolderModule } from './lib/services/multipart-holder/multipart-form-holder.module';

// Being used to register custom icons
import { MatIconModule } from '@angular/material/icon';

import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { LOCAL_STORAGE_ENGINE, NgxsStoragePluginModule, SESSION_STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { GridsterComponent, GridsterItemComponent } from 'angular-gridster2';
import jwt_decode, { InvalidTokenError } from 'jwt-decode';
import player from 'lottie-web/build/player/lottie_svg';
import { NgxJdenticonModule } from 'ngx-jdenticon';

import { LayoutsModule } from './Modules/layouts/layouts.module';
import { SharedModule } from './Modules/shared/shared.module';
import { ErrorHandlerInterceptor } from './lib/error-interceptor';
import { Config } from './lib/services/config-service/config.type';
import { InitializationService } from './lib/services/initialization-service/initialization.service';
import { MetadataService } from './lib/services/metadata-service/metadata-service.service';
import { AccountState } from './lib/state/account.state';
import { BeneficiaryState } from './lib/state/beneficiary.state';
import { CardManagementState } from './lib/state/card-management.state';
import { CardState } from './lib/state/card.state';
import { ChartState } from './lib/state/chart.state';
import { FormActionState } from './lib/state/form-submision.state';
import { MonthlySummaryState } from './lib/state/monthlysummary.state';
import { PrivacyWidgetState } from './lib/state/privacy-widget.state';
import { ProgressBarState } from './lib/state/progress-bar.state';
import { RoleState } from './lib/state/role.state';
import { TransactionState } from './lib/state/transaction.state';
import { ProfileState } from './lib/state/user-profile.state';
import { UsersState } from './lib/state/users.state';
import { TokenInterceptor } from './lib/token-interceptor';
import { isAuthenticatedRoute } from './lib/utils/auth-checks.utils';

import { CurrencyPipe, DatePipe } from '@angular/common';
import { UiComponentsModule } from '@finxone-platform/ui-components';
import Hotjar from '@hotjar/browser';
import { KeycloakCustomService } from './lib/services/keycloak-wrapper-service/keycloak-custom.service';
import { KeycloakWrapperService } from './lib/services/keycloak-wrapper-service/keycloak-wrapper.service';
import { CopResponseState } from './lib/state/cop-response.state';
import { ImageActionState } from './lib/state/image-submision.state';
import { PaymentState } from './lib/state/payment.state';

import * as Sentry from '@sentry/angular-ivy';

import { InMemoryCache } from '@apollo/client/core';
import { CountriesCurrenciesService } from '@app/finxone-web-frontend/app/lib/services/countries-currencies-service/countries-currencies.service';
import { PageContextState } from '@app/finxone-web-frontend/app/lib/state/page-context.state';
import { IconCacheService } from '@finxone-platform/shared/services';
import {
  KybStatus,
  KycStatus,
  ProfileCompletionStatus,
  ProfileStateModel,
  UserInfoType,
} from '@finxone-platform/shared/sys-config-types';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { provideLottieOptions } from 'ngx-lottie';
import { FooterNavbarComponent } from './Modules/layouts/footer-navbar/footer-navbar.component';
import { GetProfile } from './lib/actions/user-profile.action';
import { RequestUrlSanitizerInterceptor } from './lib/request-url-santizer-interceptor';
import { WorkflowFormsModule } from './lib/services/workflow-forms/workflow-forms.module';
import { ApiLoadingState } from './lib/state/api-loading.state';
import { AppDataState } from './lib/state/app-data.state';
import { BulkPaymentHistoryState } from './lib/state/bulk-payment-history.state';
import { BulkPaymentListState } from './lib/state/bulk-payment.state';
import { CrudEntityState } from './lib/state/crud-entity.state';
import { CustomQuestionState } from './lib/state/custom-question.state';
import { EmployeeListState } from './lib/state/employee-list.state';
import { EventGuestState } from './lib/state/event-guest.state';
import { FeeManagementState } from './lib/state/fee-management.state';
import { LocaleVariableState } from './lib/state/locale-variable.state';
import { ManageUserDetailState } from './lib/state/manage-user-detail.state';
import { NotificationState } from './lib/state/notifications.state';
import { OrganisationState } from './lib/state/organisation.state';
import { PaymentRequestState } from './lib/state/payment-request.state';
import { PaymentSummaryCounterState } from './lib/state/payment-summary-counters.state';
import { ProjectAlertsState } from './lib/state/project-alerts.state';
import { ProjectSettingsState } from './lib/state/project-settings.state';
import { RevenirState } from './lib/state/revenir.state';
import { TravelMgmtEntityState } from './lib/state/travel-mgmt-entity.state';
import { TripsState } from './lib/state/trips.state';
import { UsersListState } from './lib/state/users-list.state';
import {
  clearLocalStorageExcept,
  getLoginRedirectUri,
  setAlternativeThemeFromQueryParam,
} from './lib/utils/utils';

const keycloakService = new KeycloakService();

export const ngxsWebFrontendStateStorageMethods = [
  {
    key: AppDataState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: AccountState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: PaymentState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: CardState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: TransactionState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: BeneficiaryState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: RoleState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: ProgressBarState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: ProfileState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: ChartState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: UsersState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: FormActionState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: ImageActionState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: CopResponseState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  { key: TravelMgmtEntityState, engine: SESSION_STORAGE_ENGINE },
  { key: UsersListState, engine: SESSION_STORAGE_ENGINE },
  { key: EmployeeListState, engine: SESSION_STORAGE_ENGINE },
  {
    key: ManageUserDetailState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: TripsState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: NotificationState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: CustomQuestionState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: PageContextState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: OrganisationState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: CrudEntityState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: EventGuestState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: PaymentRequestState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  { key: BulkPaymentListState, engine: SESSION_STORAGE_ENGINE },
  {
    key: BulkPaymentHistoryState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: ProjectSettingsState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: FeeManagementState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: RevenirState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: ApiLoadingState,
    engine: SESSION_STORAGE_ENGINE,
  },
  {
    key: ProjectAlertsState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: PaymentSummaryCounterState,
    engine: LOCAL_STORAGE_ENGINE,
  },
];
const appInitializeMessage = '[INFO] App initialization:';
const appInitializeErrorMessage = '[ERROR] App initialization:';
class Logger {
  info(message: string): void {
    console.info(`%c ${appInitializeMessage} ${message}`, 'background: #92e9fd;');
  }
}
const logger = new Logger();

@NgModule({
  declarations: [AppComponent, LoginComponent],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    NgxsModule.forRoot([
      AppDataState,
      AccountState,
      PaymentState,
      CardState,
      TransactionState,
      MonthlySummaryState,
      BeneficiaryState,
      CopResponseState,
      RoleState,
      CardManagementState,
      ProfileState,
      ProgressBarState,
      ChartState,
      PrivacyWidgetState,
      UsersState,
      FormActionState,
      ImageActionState,
      TripsState,
      TravelMgmtEntityState,
      CustomQuestionState,
      NotificationState,
      PageContextState,
      CrudEntityState,
      OrganisationState,
      UsersListState,
      ManageUserDetailState,
      EventGuestState,
      EmployeeListState,
      PaymentRequestState,
      BulkPaymentListState,
      BulkPaymentHistoryState,
      ProjectSettingsState,
      FeeManagementState,
      RevenirState,
      ApiLoadingState,
      ProjectAlertsState,
      LocaleVariableState,
      PaymentSummaryCounterState,
    ]),
    NgxsStoragePluginModule.forRoot({
      key: ngxsWebFrontendStateStorageMethods,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    TriStateCheckboxModule,
    AutoCompleteModule,
    UiComponentsModule,
    MultipartFormHolderModule.forRoot(),
    WorkflowFormsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule.withConfig({
      warnOnNgModelWithFormControl: 'never',
    }),
    BrowserAnimationsModule,
    FooterNavbarComponent,
    LayoutsModule,
    MatIconModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxJdenticonModule,
    GridsterComponent,
    GridsterItemComponent,
    SharedModule,
    ApolloModule,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    CountriesCurrenciesService,
    IconCacheService,
    provideLottieOptions({
      player: () => player,
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestUrlSanitizerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    AppAuthGuard,
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    {
      provide: DomSanitizer,
      useValue: new ɵDomSanitizerImpl(document),
    },
    {
      provide: KeycloakWrapperService,
      deps: [KeycloakService, HttpClient],
    },
    {
      provide: KeycloakCustomService,
      deps: [KeycloakWrapperService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [
        KeycloakWrapperService,
        ConfigService,
        Router,
        MetadataService,
        InitializationService,
        Sentry.TraceService,
      ],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          },
          link: httpLink.create({
            uri: `https://if.development.finxone.com/api/entity/v1/graphql`,
            headers: {
              'content-type': 'application/json',
              'x-hasura-admin-secret': 'Y2JMbhSrsr3C5BLkw7e69d1p',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            } as any,
          }),
        };
      },
      deps: [HttpLink, ConfigService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function initialize(
  keycloak: KeycloakWrapperService,
  configService: ConfigService,
  router: ActivatedRoute,
  metaService: MetadataService,
  initializationService: InitializationService,
) {
  return () => {
    //clearing role from sessionStorage on app initialise, this is to combat role being remembered sometimes possibly causing the white screen
    sessionStorage.removeItem('role');
    return configService.getConfig().pipe(
      take(1),
      switchMap<any, any>((config: Config) => {
        configChecker(config);

        if (config.hotjarSiteId) {
          Sentry.addBreadcrumb({
            category: 'hotjar',
            message: 'Init Hotjar',
            level: 'info',
          });

          const hotjarVersion = 6;
          Hotjar.init(config.hotjarSiteId, hotjarVersion);
          Sentry.addBreadcrumb({
            category: 'hotjar',
            message: 'Hotjar has been inited',
            level: 'info',
          });
        }

        Sentry.addBreadcrumb({
          category: 'theme',
          message: 'Setting alternative theme from query param',
          level: 'info',
        });
        setAlternativeThemeFromQueryParam();

        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Initing keycloak js library',
          level: 'info',
        });

        return from(
          keycloak
            .init({
              config: {
                url: config.api_endpoints['auth_service'],
                realm: config.auth.realm,
                clientId: config.auth.client_id,
              },
              initOptions: {
                enableLogging: false,
                flow: 'standard',
                checkLoginIframe: false,
              },
              shouldAddToken: (request) => {
                // if a get request and on /assets then don't add auth header to httpclient
                const { url } = request;
                const acceptablePaths = ['/assets', '/clients/public', '.svg'];
                const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path));
                return !isAcceptablePathMatch;
              },
              updateMinValidity: 60,
              shouldUpdateToken(request) {
                return true;
              },
            })
            .then(async (auth) => {
              logger.info('Token Added');

              try {
                const token = localStorage.getItem('token');

                // Add hook to update access token on expiry to keycloak
                logger.info('Token Updated on Expiry Hook');
                if (auth) {
                  Sentry.addBreadcrumb({
                    category: 'auth',
                    message: 'Initing keycloak js - user inited and proceeding',
                    level: 'info',
                  });
                  // Happy path user is authed and initialise app as normal and store the tokens
                  // Should get triggered after redirect back from keycloak
                  await initAuthedApplication(keycloak, initializationService);
                } else if (localStorage.getItem('refreshToken') && token) {
                  Sentry.addBreadcrumb({
                    category: 'auth',
                    message: 'Initing keycloak js - user not authed but refresh token present',
                    level: 'info',
                  });

                  if (!(await checkCurrentTokenExpirationAndRenew(token, keycloak))) {
                    logger.info(`Expired or invalid token triggering relogin`);
                    Sentry.addBreadcrumb({
                      category: 'auth',
                      message: 'Initing keycloak js - exired refresh sending to login',
                      level: 'info',
                    });
                    keycloak.login({
                      redirectUri: window.location.origin + '/post-auth',
                    });
                  }
                  configService.setRoleInfo(token);
                  logger.info(`roleInfo set= ${configService.roleInfo.role}`);
                } else if (
                  isAuthenticatedRoute(window.location.pathname) &&
                  window.location.pathname !== '/'
                ) {
                  Sentry.addBreadcrumb({
                    category: 'auth',
                    message: 'Initing keycloak js - not authed not on authed path sending to login ',
                    level: 'info',
                  });
                  logger.info(`No valid token found and authenticated route, triggering login`);
                  keycloak.login({
                    redirectUri: getLoginRedirectUri(),
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: 'auth',
                    message: 'Initing keycloak js - not authed but no auth needed just setting devic ',
                    level: 'info',
                  });
                  configService.setDevice();
                  logger.info(
                    `Device set
                    ${configService.getDeviceData().deviceType}`,
                  );
                }
              } catch (error) {
                console.error(appInitializeErrorMessage, 'Error during authentication initialization', error);
                Sentry.captureException(error);
                keycloak.login({
                  redirectUri: getLoginRedirectUri(),
                });
              }
            }),
        );
      }),
    );
  };
}

export async function initAuthedApplication(
  keycloak: KeycloakWrapperService,
  initializationService: InitializationService,
) {
  const token = await keycloak.getToken();
  clearLocalStorageExcept([
    'account',
    'transactions',
    'beneficiary',
    'notifications',
    'lastLoggedInId',
    'APP_PREFERENCES',
    'firstTime',
  ]);
  logger.info(`Valid token found, init application`);
  localStorage.setItem('token', token);
  const refreshToken = keycloak.getKeycloakInstance().refreshToken as string;
  localStorage.setItem('refreshToken', refreshToken);
  initializationService.initializeApp(token);
}

/*
  Used to check if token is expired and renew if so
  Returns false if no expiry set so a login should be triggered
*/
export async function checkCurrentTokenExpirationAndRenew(token: string, keycloak: KeycloakWrapperService) {
  if (token === undefined || token === 'undefined') {
    logger.info('Token is undefined in check current token and renew');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    keycloak.login({
      redirectUri: getLoginRedirectUri(),
    });
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const jwt: any = jwt_decode(token);

    if (jwt.exp) {
      logger.info('Token is expired, renewing');
      // Check token expiry if expired renew?
      const expiryDate = new Date(jwt.exp * 1000);
      if (expiryDate < new Date()) {
        const token = await keycloak.getToken();
        if (token) localStorage.setItem('token', token);
        const refreshToken = keycloak.getKeycloakInstance().refreshToken as string;
        if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
      }

      return true;
    } else {
      return false;
    }
  } catch (err) {
    // if token decode failed then just send to login
    Sentry.captureException(err);
    keycloak.login({
      redirectUri: getLoginRedirectUri(),
    });
    return false;
  }
}

/*
  Used to ensure the if kyc is required and staus is not complete we redirect
  user to correct pages to complete
*/
export async function checkVerificationStatusAndRedirect(
  token: string,
  router: Router,
  metaService: MetadataService,
  store: Store,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  try {
    const jwt: any = jwt_decode(token);
    const userId = jwt.sub;
    store.dispatch(new GetProfile());
    metaService.getUser(userId).subscribe((resProfile) => {
      verifyRoute(router, resProfile as UserInfoType & ProfileStateModel, true, store);
    });
  } catch (err) {
    Sentry.captureException(err);
    if (err instanceof InvalidTokenError) {
      router.navigateByUrl('');
    } else router.navigateByUrl('');
  }
}

export function verifyRoute(
  router: Router,
  profile: UserInfoType & ProfileStateModel,
  isLandingRoutingEnable = true,
  store?: Store,
): boolean {
  const {
    organisationKybStatus,
    activeOrganisationKybStatus,
    role,
    activeRole,
    profileCompletionStatus,
    kycVerificationStatus,
  } = profile || {};

  const isReviewPending =
    organisationKybStatus === KybStatus.REVIEW_PENDING ||
    activeOrganisationKybStatus === KybStatus.REVIEW_PENDING;

  const isPending =
    organisationKybStatus === KybStatus.PENDING ||
    organisationKybStatus === KybStatus.STARTED ||
    activeOrganisationKybStatus === KybStatus.PENDING ||
    activeOrganisationKybStatus === KybStatus.KYB_PENDING ||
    activeOrganisationKybStatus === KybStatus.FAILED ||
    activeOrganisationKybStatus === KybStatus.STARTED ||
    activeOrganisationKybStatus === KybStatus.REVIEW_PENDING ||
    activeOrganisationKybStatus === KybStatus.IN_PROGRESS ||
    profileCompletionStatus === ProfileCompletionStatus.PENDING ||
    kycVerificationStatus === KycStatus.PENDING ||
    kycVerificationStatus === KycStatus.KYC_PENDING ||
    kycVerificationStatus === KycStatus.FAILED ||
    kycVerificationStatus === KycStatus.IN_PROGRESS ||
    kycVerificationStatus === KycStatus.STARTED ||
    kycVerificationStatus === KycStatus.NOT_STARTED;

  const isOrgNotAvailable = !!(profile.orgList?.length === 0);

  const isOwner = activeRole === 'owner' || role === 'owner';

  const redirectToOrgOnboardingFlow =
    store?.selectSnapshot(FormActionState.getFormActionState)?.response?.formData
      ?.redirectToOrgOnboardingFlow ?? false;

  if (redirectToOrgOnboardingFlow) {
    router.navigateByUrl('/zones/organisation-onboarding/onboarding/organisation-anonymous/default');
    return true;
  }
  if ((isReviewPending && isOwner) || isPending || (isOrgNotAvailable && isOwner)) {
    router.navigateByUrl('zones/verification/status-screen');
    return true;
  } else {
    if (isLandingRoutingEnable) {
      router.navigateByUrl('zones/landing/home');
    }
    return false;
  }
}

function configChecker(config: Config) {
  try {
    if (!config.hotjarSiteId) {
      console.error(appInitializeErrorMessage, 'Missing config.hotjarSiteId');
    } else {
      logger.info(`config.hotjarSiteId= ${config.hotjarSiteId}`);
    }

    if (!config.api_endpoints['auth_service']) {
      console.error(appInitializeErrorMessage, "Missing config.api_endpoints['auth_service']");
      throw new Error("Missing config.api_endpoints['auth_service']");
    } else {
      logger.info(`config.api_endpoints['auth_service']= ${config.api_endpoints['auth_service']}`);
    }

    if (!config.auth.realm) {
      console.error(appInitializeErrorMessage, 'Missing config.auth.realm');
      throw new Error('Missing config.auth.realm');
    } else {
      logger.info(`config.auth.realm= ${config.auth.realm}`);
    }

    if (!config.auth.client_id) {
      console.error(appInitializeErrorMessage, 'Missing config.auth.client_id');
      throw new Error('Missing config.auth.client_id');
    } else {
      logger.info(`clientId= ${config.auth.client_id}`);
    }

    // Continue with the app initialization process...
  } catch (error) {
    if (error instanceof Error) {
      console.error(appInitializeErrorMessage, 'App initialization failed:', error.message);
    } else {
      console.error(appInitializeErrorMessage, 'App initialization failed with an unknown error.');
    }
    // Stop the loading process or take other necessary actions
  }
}

import { Injectable } from '@angular/core';
import { supportedFonts } from '@finxone-platform/shared/sys-config-types';

@Injectable({
  providedIn: 'root',
})
export class FontInjectorService {
  private loadedFonts: Set<string> = new Set();

  private allowedGoogleFonts = supportedFonts
    .filter((font) => font.type === 'Google')
    .map((font) => font.name);

  private allowedCustomFonts = supportedFonts
    .filter((font) => font.type === 'Custom')
    .map((font) => font.name);

  injectFont(fontName: string): void {
    if (![...this.allowedCustomFonts, ...this.allowedGoogleFonts].includes(fontName)) {
      console.warn(`Font ${fontName} is not a supported font, check that it's added properly.`);
    }

    if (!this.allowedCustomFonts.includes(fontName)) {
      return; // Custom font not included in project
    }

    if (this.loadedFonts.has(fontName)) {
      return; // Font already loaded
    }

    const weights = ['Light', 'Regular', 'Medium', 'Bold', 'Book', 'SemiBold', 'ExtraBold']; // Modify based on your font files

    weights.forEach((weight) => {
      const preloadLink = this.createPreloadLink(fontName, weight);
      document.head.appendChild(preloadLink);
    });

    const stylesheetLink = this.createStylesheetLink(fontName);
    document.head.appendChild(stylesheetLink);

    this.loadedFonts.add(fontName);
  }

  private createPreloadLink(fontName: string, weight: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'font';
    link.type = 'font/woff2';
    link.href = `/assets/fonts/${fontName}-${weight}.woff2`; // Adjust path as necessary
    link.crossOrigin = 'anonymous';
    return link;
  }

  private createStylesheetLink(fontName: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `/assets/fonts/${fontName}.font.css`; // Adjust path as necessary
    return link;
  }
}
